export const workExperience = [
	{
		_id: 13,
		title: 'Principal Engineer - Data Platforms and Insights',
		subtitle: 'Autodesk - San Franciso (remotely from Seattle)',
		tertiaryTitle: 'October 2020 - May 2022',
		descriptions: [
			'On the software side:',
			'- Similar to a software architect, I consulted multiple internal teams across the globe on scaling their web applications, improving coding patterns, and integrating CI/CD best-practices.',
			'- Laid out a technical foundation for projects and developed alongside the teams with various technologies including AWS, Postgres, React, Node.js, Python, and TypeScript.',
			'On the data side:',
			'- Identified and incorporated best-practices for automating and monitoring aging data pipelines.',
			'- Improved coding patterns and practices for repositories using Spark, Python, Oozie, Jenkins, PySpark, S3, and Qubole.',
		],
		skills: {
			AWS: 'MED',
			Python: 'HIGH',
			'Node.js': 'HIGH',
			React: 'HIGH',
			PySpark: 'MED',
			Postgres: 'MED',
			TypeScript: 'HIGH',
		},
	},
	{
		_id: 12,
		title: 'Principal Engineer and Team Lead - Backend Platform',
		subtitle: 'Autodesk - San Francisco',
		tertiaryTitle: 'July 2019 - October 2020',
		descriptions: [
			'(Autodesk acquired BuildingConnected in January 2019)',
			'- Assisted with distributing ownership of key BE infrastructure to feature teams.',
			'- For part of this time, I also lead our team of 6 and thus focussed on people management and strategic planning.',
			'- Participated in a BE Pattern Maintainers group to help guide our next generation of Node.js BE patterns.',
			'- Project managed our efforts to better scale our systems (both in terms of people/process challenges around ownership and accountability but also our backend system scaling to meet high-growth demands).',
		],
		skills: {
			'Node.js': 'MAX',
			'People Management': 'MED',
			'Vision/Strategy': 'HIGH',
			'Project Management': 'MED',
			MongoDB: 'MAX',
			'Parallel and Distributed Computing': 'MED',
		},
	},
	{
		_id: 11,
		title: 'Engineering Manager',
		subtitle: 'BuildingConnected - San Francisco',
		tertiaryTitle: 'June 2018 - July 2019',
		descriptions: [
			'- Helped guide Engineering Leads for three full-stack feature teams with regular 1:1s, team support, team restructuring, and change management.',
			'- Participated in steering conversations with other Engineering Managers like rewriting our BE patterns, making our teams more autonomous and empowered, and level setting and conducting quarterly assessments across the engineering organization.',
			'- As a Division Leader, I oversaw high-level engineering and planning for our preconstruction product which is used by over 250,000 subcontractor offices.',
		],
		skills: {
			'People Management': 'MAX',
			'Vision/Strategy': 'HIGH',
			'Project Management': 'MED',
		},
	},
	{
		_id: 10,
		title: 'Engineering Lead',
		subtitle: 'BuildingConnected - San Francisco',
		tertiaryTitle: 'Sep 2017 - June 2018',
		descriptions: [
			'- As a servant leader, I assisted the team by unblocking team members, reviewing production code, and collaborating with Product and Design to break down large problems into parallelizable tasks.',
			'- Practiced agile/scrum methodologies with 2 week sprints (Trello then we moved to Jira) and lead other regular meetings like stand-ups, 1:1s, and sprint planning/grooming sessions.',
			'- Worked with other Leads as well as the CTO to help guide processes like improving our hiring documents, implementing 360 reviews, and training/documenting backend patterns and best practices.',
		],
		skills: {
			'People Management': 'HIGH',
			Agile: 'MED',
			'Vision/Strategy': 'MED',
			'Node.js': 'LOW',
			React: 'LOW',
			MongoDB: 'LOW',
			'Project Management': 'LOW',
		},
	},
	{
		_id: 9,
		title: 'Full Stack Engineer',
		subtitle: 'BuildingConnected - San Francisco',
		tertiaryTitle: 'Sep 2016 - Sep 2017',
		descriptions: [
			'- Designed and developed scalable, dependable, well-tested code to power a fast-paced software product.',
			'- Implemented solutions such as a standalone printing service, a denormalization engine to power materialized views in MongoDB, and BE/FE features for our premium subcontractor product',
			'Specialized mostly in backend systems, complex data models, and migrations.',
		],
		skills: {
			'Node.js': 'MAX',
			React: 'MED',
			MongoDB: 'MAX',
		},
	},
	{
		_id: 8,
		title: 'Co-Founder and Lead Engineer',
		subtitle: 'Lever Insights, LLC - Seattle',
		tertiaryTitle: 'Feb 2016 - July 2016',
		descriptions: [
			'- Designed a full-stack solution for a data visualization web application.',
			'- Developed a Single-Page Application (SPA) prototype using Node.js, Express, AngularJS, D3, Bootstrap, Sass.',
		],
		skills: {
			AngularJS: 'MAX',
			D3: 'HIGH',
			Bootstrap: 'MED',
		},
	},
	{
		_id: 7,
		title: 'Graduate Teaching Assistant',
		subtitle: 'Department of Computer Science - University of Denver',
		tertiaryTitle: 'Sep 2013 - Sep 2015',
		descriptions: [
			'- Worked one-on-one with CS students while assisting professors with grading and teaching.',
			'- Classes: Web Programming, Parallel and Distributed Programming, Programming Languages, AI, etc.',
		],
		skills: {
			'Object-Oriented Design': 'MAX',
			'Parallel and Distributed Computing': 'HIGH',
			'iOS with Objective-C': 'MED',
			'Artificial Intelligence': 'MED',
			'Tutoring and Mentoring': 'HIGH',
		},
	},
	{
		_id: 6,
		title: 'Software Engineer',
		subtitle: 'New Signature - Albuquerque',
		tertiaryTitle: 'Nov 2012 - June 2013',
		descriptions: [
			'- Executed on full-stack tasks such as backend server admin/security, site migrations/deployments, and testing and framework updates (PHP, Drupal, WordPress, LAMP).',
			'- Independently designed/implemented software for handling internal client tickets and emails (C#).',
		],
		skills: {
			'C#': 'MAX',
			Drupal: 'HIGH',
			Wordpress: 'HIGH',
			'LAMP Stack': 'MED',
		},
	},
	{
		_id: 5,
		title: 'Technology Specialist',
		subtitle: 'OVATION, Inc - Nashville',
		tertiaryTitle: 'Jan 2012 - May 2012',
		descriptions: [
			'- Managed infrastructure, data streaming, and software for large conferences.',
			'- Independently designed/implemented two software packages (C#) aimed to reduce wait times for presenters.',
		],
		skills: {
			'C#': 'MAX',
			MySQL: 'HIGH',
			UNIX: 'MED',
		},
	},
	{
		_id: 4,
		title: 'Undergraduate Research Assistant',
		subtitle: 'Institute for Software Integrated Systems - Nashville',
		tertiaryTitle: 'May 2010 - Aug 2010',
		descriptions: [
			'- Prototyped a data-streaming solution for ingesting large amounts of real-time traffic data',
		],
		skills: {
			'Parallel and Distributed Computing': 'HIGH',
			Java: 'MED',
		},
	},
	{
		_id: 3,
		title: 'Summer Intern',
		subtitle: 'Visible Light Solar Technologies - Albuquerque',
		tertiaryTitle: 'May 2009 - August 2009',
		descriptions: [
			'- Designed software for intelligent lighting profiles on embedded systems powered by solar panels.',
			'- Built solar panels using a laser cutter and soldering iron.',
		],
		skills: {
			'C/C++': 'HIGH',
		},
	},
]

export const projects = [
	{
		_id: 2,
		title: 'Homepage - Revised',
		subtitle: 'React - version 2',
		tertiaryTitle: 'Project on GitHub',
		tertiaryUrl: 'https://github.com/zachazar/homepage-react',
		descriptions: [
			'I revised my website using React instead of Angular. Additionally, I used create-react-app, D3, Bulma, Gatsby, and deployed with Netlify.',
		],
		skills: {
			React: 'MAX',
			Gatsby: 'MED',
			Netlify: 'MED',
		},
	},
	{
		_id: 1,
		title: 'Homepage',
		subtitle: 'Angular - version 1',
		tertiaryTitle: 'Project on GitHub',
		tertiaryUrl: 'https://github.com/zachazar/homepage',
		descriptions: [
			'Built a personal homepage (this website) using Angular, D3, Bootstrap, PHP, Webpack, Sass.',
		],
		skills: {
			AngularJS: 'MAX',
			Bootstrap: 'HIGH',
		},
	},
]

export const education = [
	{
		_id: -1,
		title: 'M.S. in Computer Science',
		subtitle: 'University of Denver (2013 - 2016)',
		tertiaryTitle: 'GPA: 3.97',
		descriptionTitle:
			'Thesis: "PECCit - An Omniscient Debugger for Web Development"',
		descriptions: [
			'PECCit is a multi-application software system which offers a tool for improved backend debugging. Built using PHP, C++, Linux, MySQL, JavaScript, jQuery, AJAX, a REST API, HTML, CSS, Bootstrap, etc.',
		],
		skills: {
			PHP: 'HIGH',
			MySQL: 'HIGH',
			'Advanced Data Structures and Algorithms': 'MAX',
			'Parallel and Distributed Computing': 'HIGH',
		},
	},
	{
		_id: -2,
		title: 'B.S. in Computer Science',
		subtitle: 'Vanderbilt University (2007 - 2011)',
		tertiaryTitle: 'GPA: 3.70',
		descriptions: [
			'Minors: Engineering Management, Mathematics',
			"Honors: Cum Laude, Honors in Computer Science, Dean's List",
		],
		skills: {
			'Object-Oriented Design': 'MAX',
			Java: 'HIGH',
			'C/C++': 'HIGH',
			'Design Patterns': 'MED',
			'Advanced Data Structures and Algorithms': 'HIGH',
			UNIX: 'LOW',
		},
	},
]
